@tailwind components;

@layer components {
  .rich .editor {
    /* Blockquote styles */
    blockquote {
      @apply relative pl-4;
      &::before {
        content: "";
        @apply absolute bottom-1 left-1 top-1 border-l-2 border-dusk-border-strong;
      }
    }
    /* List styles */
    ul {
      li::before {
        content: "•";
        @apply float-start mr-2;
      }
    }

    ol {
      counter-reset: list;
      li {
        counter-increment: list;
      }
      li::before {
        content: counter(list) "-";
        @apply float-start mr-2;
      }
    }

    /* Heading styles */
    h2 {
      @apply font-bold;
      /** It depends on the base font size of the editor, enabling scalable handling using `em` units */
      font-size: 1.25em;
    }

    h3 {
      @apply font-bold;
      font-size: 1.125em;
    }

    /* Horizontal rule styles */
    hr {
      @apply flex items-center justify-center border-0 py-4;
      &::before {
        content: "";
        @apply flex-1 border-t border-dusk-border-strong;
      }
      &.ProseMirror-selectednode {
        &::before {
          @apply border-dusk-border;
        }
      }
    }

    a {
      @apply border-b border-blue-border text-primary-on transition-all hover:border-b-2;
      @apply focus:rounded-t-sm focus:bg-blue-bg-hover-transparent;
      @apply data-[affiliate]:text-primary-200 data-[affiliate]:hover:text-primary-300;
    }
  }

  [data-type="emoji"] img {
    @apply inline h-[1em];
  }

  .Tiptap-invisible-character {
    height: 0;
    padding: 0;
    pointer-events: none;
    user-select: none;
    width: 0;

    & + img.ProseMirror-separator {
      height: 0 !important;
      pointer-events: none;
      user-select: none;
      width: 0 !important;
    }

    &::before {
      content: "";
      caret-color: inherit;
      @apply text-dusk-on opacity-disabled;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 1em;
      width: 0;
    }

    .is-empty[data-placeholder].has-focus > & {
      display: none;
    }

    &[data-type="space"]::before {
      content: "·";
    }

    &[data-type="no-break-space"]::before {
      content: "·";
      @apply text-red-on;
    }

    &[data-type="soft-hyphen"]::before {
      content: "·";
      @apply text-orange-on;
    }

    &[data-type="break"]::before {
      content: "¬";
    }

    &[data-type="paragraph"]::before {
      content: "¶";
    }
  }

  .anchor {
    @apply border-b border-dashed border-blue-border transition-all hover:border-b-2;
    @apply focus:rounded-t-sm focus:bg-blue-bg-hover-transparent;
  }

  .search-result {
    @apply bg-grey-100;
  }
  .search-result.search-result-current {
    @apply bg-primary-100;
  }

  .is-hovered {
    @apply outline outline-1 outline-secondary-border-hover-light;
  }
}
