@import "editor/editor.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-accent: theme(fontFamily.accent);
    --font-sans: theme(fontFamily.sans);

    --color-text: theme(colors.dusk.on.DEFAULT);
  }

  body {
    font-family: var(--font-sans);
    color: var(--color-text);
  }
}

@layer components {
  .scrollbar-light {
    @apply scrollbar-thin scrollbar-track-grey-bg-light scrollbar-thumb-grey-bg;
  }

  .scrollbar-dark {
    @apply scrollbar-thin scrollbar-track-dusk-bg-stronger scrollbar-thumb-dusk-600;
  }
}

/* Fonts */

/* Proxima Nova */

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Fallback";
  font-style: normal;
  font-weight: 100;
  src: local("Arial");
  ascent-override: 95.64%;
  descent-override: 30.96%;
  line-gap-override: 0%;
  size-adjust: 96.19%;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Fallback";
  font-style: normal;
  font-weight: 400;
  src: local("Arial");
  ascent-override: 92.86%;
  descent-override: 30.06%;
  line-gap-override: 0%;
  size-adjust: 99.07%;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Fallback";
  font-style: normal;
  font-weight: 600;
  src: local("Arial Bold");
  ascent-override: 84.62%;
  descent-override: 22.49%;
  line-gap-override: 0%;
  size-adjust: 93.36%;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Fallback";
  font-style: normal;
  font-weight: 700;
  src: local("Arial Bold");
  ascent-override: 97.12%;
  descent-override: 31.44%;
  line-gap-override: 0%;
  size-adjust: 94.72%;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Extrabold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Fallback";
  font-style: normal;
  font-weight: 800;
  src: local("Arial Bold");
  ascent-override: 95.5%;
  descent-override: 30.92%;
  line-gap-override: 0%;
  size-adjust: 96.33%;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Fallback";
  font-style: normal;
  font-weight: 900;
  src: local("Arial Bold");
  ascent-override: 93.97%;
  descent-override: 30.42%;
  line-gap-override: 0%;
  size-adjust: 97.9%;
}

/* Source Sans Pro */

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: normal;
  font-weight: 200;
  src: local("Arial");
  ascent-override: 110.05%;
  descent-override: 30.53%;
  line-gap-override: 0%;
  size-adjust: 89.41%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: normal;
  font-weight: 300;
  src: local("Arial");
  ascent-override: 108.45%;
  descent-override: 30.09%;
  line-gap-override: 0%;
  size-adjust: 90.73%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: normal;
  font-weight: 400;
  src: local("Arial");
  ascent-override: 104.47%;
  descent-override: 28.98%;
  line-gap-override: 0%;
  size-adjust: 94.19%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: normal;
  font-weight: 600;
  src: local("Arial Bold");
  ascent-override: 109.5%;
  descent-override: 30.38%;
  line-gap-override: 0%;
  size-adjust: 89.87%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: normal;
  font-weight: 700;
  src: local("Arial Bold");
  ascent-override: 106.34%;
  descent-override: 29.5%;
  line-gap-override: 0%;
  size-adjust: 92.53%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: normal;
  font-weight: 900;
  src: local("Arial Bold");
  ascent-override: 103.96%;
  descent-override: 28.84%;
  line-gap-override: 0%;
  size-adjust: 94.65%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-ExtraLightItalic.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: italic;
  font-weight: 200;
  src: local("Arial Italic");
  ascent-override: 111.91%;
  descent-override: 31.05%;
  line-gap-override: 0%;
  size-adjust: 87.93%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: italic;
  font-weight: 300;
  src: local("Arial Italic");
  ascent-override: 110.53%;
  descent-override: 30.66%;
  line-gap-override: 0%;
  size-adjust: 89.03%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: italic;
  font-weight: 400;
  src: local("Arial Italic");
  ascent-override: 106.83%;
  descent-override: 29.64%;
  line-gap-override: 0%;
  size-adjust: 92.1%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-SemiBoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: italic;
  font-weight: 600;
  src: local("Arial Bold Italic");
  ascent-override: 112.46%;
  descent-override: 31.2%;
  line-gap-override: 0%;
  size-adjust: 87.5%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: italic;
  font-weight: 700;
  src: local("Arial Bold Italic");
  ascent-override: 109.51%;
  descent-override: 30.38%;
  line-gap-override: 0%;
  size-adjust: 89.86%;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro Fallback";
  font-style: italic;
  font-weight: 900;
  src: local("Arial Bold Italic");
  ascent-override: 107.32%;
  descent-override: 29.77%;
  line-gap-override: 0%;
  size-adjust: 91.69%;
}

/* Remove spin of number input */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Loader */

svg.swash-loader {
  animation: 2s linear infinite svg-animation;
  max-width: 100px;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

svg.swash-loader circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: theme(colors.primary.500);
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

/* Skeleton */

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Dialog Panel */

.swash-dialog-panel {
  &:focus {
    outline: none;
  }

  &[data-animated] {
    &[data-animation="from-right"] {
      @apply transition;
      opacity: 0;
      transform-origin: right center;
      transform: translateX(20%);

      &[data-enter] {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &[data-animation="from-right-complete"] {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 250ms;

      transform-origin: right center;
      transform: translateX(110%);

      &[data-enter] {
        transition-duration: 300ms;
        transform: translateX(0);
      }
    }
  }
}

/* Audio player */
input[type="range"].audio-player-controls {
  appearance: none;
  -webkit-appearance: none;
  height: 4px;
  background-color: theme(colors.grey.100);
  border-radius: 25px;
  background-image: linear-gradient(
    theme(colors.blue.300),
    theme(colors.blue.300)
  );
  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    box-shadow:
      0px 0.5px 4px rgba(0, 0, 0, 0.12),
      0px 6px 13px rgba(0, 0, 0, 0.12);
    transition: background 0.3s ease-in-out;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &[data-orientation="vertical"] {
    width: 80px;
  }
}

/* Article Analytics */
.chart-axis-label {
  -webkit-text-stroke: 3px white;
}

/* Select Label */
.select-label {
  -webkit-text-stroke: 4px white;
}
