.merciapp-spinner {
  width: 20px;
  align-self: end;
  pointer-events: none;
}

#mciapp-spinner-container {
  > div {
    position: relative;
  }
}

#spinner-icon {
  cursor: default;
}
