.day-picker {
  &.rdp-root {
    --rdp-nav-height: 28px;

    --rdp-nav_button-width: 28px;
    --rdp-nav_button-height: 28px;

    --rdp-day_button-width: 28px;
    --rdp-day_button-height: 28px;
    --rdp-day_button-border: none;

    --rdp-weekday-opacity: 1;
    --rdp-weekday-padding: 0;

    --rdp-today-color: theme(colors.primary.on.DEFAULT);

    @apply m-0 select-none font-accent text-sm text-dusk-on;
  }

  .rdp-month {
    @apply size-min;
  }

  .rdp-weekday {
    @apply text-2xs font-bold capitalize;
    width: var(--rdp-day_button-width);
    height: var(--rdp-day_button-height);
  }

  .rdp-caption_label {
    @apply px-1 text-xs font-semibold uppercase;
  }

  .rdp-button_next,
  .rdp-button_previous {
    @apply rounded-full focus-within:bg-primary-50 focus-within:outline-primary-300 hover:bg-primary-50;
  }

  .rdp-chevron {
    @apply size-3 fill-current;
  }

  .rdp-day {
    @apply size-auto px-0;
  }

  .rdp-day_button {
    @apply rounded hover:bg-primary-50;
    @apply focus-visible:!bg-primary-50 focus-visible:!text-dusk-on focus-visible:outline-1 focus-visible:outline-primary-300;
  }

  .rdp-today:not(.rdp-outside) .rdp-day_button {
    @apply font-bold;
  }

  .rdp-selected {
    font-size: inherit;
    font-weight: inherit;
  }

  .rdp-selected .rdp-day_button {
    @apply border-transparent bg-primary-300 text-white opacity-100;
  }

  .rdp-selected.rdp-range_start .rdp-day_button {
    @apply rounded-r-none;
  }

  .rdp-selected.rdp-range_middle .rdp-day_button {
    @apply rounded-none bg-primary-100 hover:bg-primary-300 hover:text-white;
    color: inherit;
  }

  .rdp-selected.rdp-range_end .rdp-day_button {
    @apply rounded-l-none;
  }

  .rdp-disabled {
    @apply opacity-disabled;
  }
}
