@tailwind components;

@layer components {
  .article-editor-container {
    @apply flex flex-col bg-white;

    .editor-header-container {
      @apply sticky inset-x-0 top-0 z-10;
    }

    .toolbar {
      @apply flex-1;
    }

    .editor {
      @apply -ml-16;
      padding-left: calc(4rem - 4px);
    }

    .editor-container,
    .article-signatures-container,
    .add-line-container {
      @apply self-center overflow-visible;
      width: calc(698px + 2 * 24px);
      @media (max-width: 1280px) {
        @apply w-full;
        max-width: 640px;
      }

      @media (max-width: 950px) {
        @apply w-full;
        margin: 0 20px;
      }
    }

    .headline {
      @apply my-4 rounded-none border-b border-transparent pb-2 font-bold;
    }

    .editor[contenteditable="true"] .headline {
      &:hover,
      &.has-focus {
        @apply border-b border-primary-border outline-0;
      }
    }

    .editor p.is-empty:first-of-type::before,
    .headline.is-empty::before {
      @apply pointer-events-none float-left h-0 text-secondary-on-light;
      content: attr(data-placeholder);
    }

    .title {
      @apply text-2xl;
    }

    .chapo {
      @apply text-xl;
    }

    /* Give a remote user a caret */
    .collaboration-cursor__caret {
      border-left: 1px solid #0d0d0d;
      border-right: 1px solid #0d0d0d;
      margin-left: -1px;
      margin-right: -1px;
      pointer-events: none;
      position: relative;
      word-break: normal;
    }

    /* Render the username above the caret */
    .collaboration-cursor__label {
      border-radius: 3px 3px 3px 0;
      color: #0d0d0d;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      left: -1px;
      line-height: normal;
      padding: 0.1rem 0.3rem;
      position: absolute;
      top: -1.4em;
      user-select: none;
      white-space: nowrap;
    }
  }
}
